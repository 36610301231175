import React, { useContext, useState } from "react";
import "./login.css";
import { Grid } from "@mui/material";
import loginImg from "../../assets/book-white-background.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginContext from "../../context/login/LoginContext";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import { baseUrl } from "../../context/baseUrl";
import ButtonLoader from "../../loader/ButtonLoader";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const LoginStatus = useContext(LoginContext);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const validateFields = () => {
    let errors = {};
    if (!email) errors.email = "Email is required!";
    if (!password) errors.password = "Password is required!";
    return errors;
  };

  async function handleOnSubmit(e) {
    e.preventDefault();
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({});
    }
    
    const raw = JSON.stringify({
      email: email,
      password: password,
    });
    setLoader(true);
    try {
      const res = await axios({
        method: "post",
        url: `${baseUrl}/auth/login`,
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(res);
      const token = res.data.data.token;
      const userId = res.data.data.other._id;
      const user = res.data.data.other;
      if (res.status === 200) {
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("userData", JSON.stringify(user));
        setLoader(false);
        setEmail("");
        setPassword("");
        // toast.success("Login Successful.");
        // setTimeout(() => {
          LoginStatus.setLoggedIn(true);
          navigate("/");
        // }, 3000);
      }
    } catch (e) {
      setLoader(false);
      toast.error(e?.response?.data?.message || "An error occurred.");
    }
    setLoader(false);
  }

  const handleForgot = () => {
    navigate('/forgot');
  };

  return (
    <>
      <AlertContainer />
      <section className="login">
        <div className="container">
          <Grid container alignItems={"center"} rowSpacing={4}>
            <Grid item md={6} xs={12} lg={6}>
              <div className="login-img">
                <img src={loginImg} alt="" />
              </div>
            </Grid>
            <Grid item md={6} xs={12} lg={5}>
              <div className="login-container">
                <div className="text-left">
                  <h2 className="login-title">Login Now</h2>
                  <hr />
                  <form onSubmit={handleOnSubmit}>
                    <div className="login-input-container">
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="input-title">
                          Email
                        </label>
                        <input
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          className="input-box"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                        {errors.email && <span className="error-message" style={{color:"red"}}>{errors.email}</span>}
                      </div>
                      <div className="d-flex flex-column">
                        <label htmlFor="" className="input-title">
                          Password
                        </label>
                        
                          <input
                            className="input-box"
                            name="password"
                            placeholder="Enter The Password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            type={showPassword ? "text" : "password"}
                          />
                          {/* <span
                            className="toggle-password"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </span> */}
                        
                        {errors.password && <span className="error-message" style={{color:"red"}}>{errors.password}</span>}
                      </div>
                    </div>
                    <button
                      className="submit-content-btn"
                      style={{ color: "brown" }}
                      onClick={(e) => handleOnSubmit(e)}
                    >
                     Submit
                    </button>
                    <button
                      className="submit-content-btn"
                      style={{ color: "brown" }}
                      onClick={handleForgot}
                    >
                      Forgot Password
                    </button>
                    <p className="mt-4">
                      Doesn't have an account? &nbsp; &nbsp;
                      <br />
                      <br />
                      <Link
                        to="/sign-in"
                        className="submit-content-btn"
                        style={{ padding: "6px 26px", color: "brown" }}
                      >
                        Register Now
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default LoginPage;
