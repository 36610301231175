import React, { useState } from "react";
import cardLogo from "../../assets/review-img.png";
import Rating from "../Rating";
import { capitalizeWords } from "../../common/toUpperCase";

const LongText = ({ content, limit }) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  if (content.length <= limit) {
    return <div>{content}</div>;
  }
  if (showAll) {
    return (
      <div>
        {content}
        <button className="read-more-btn" onClick={showLess}>
          Read less
        </button>
      </div>
    );
  }
  const toShow = content.substring(0, limit) + "...";
  return (
    <div>
      {toShow}
      <button className="read-more-btn" onClick={showMore}>
        Read more
      </button>
    </div>
  );
};

function CustomerReviewCard({ review, onDelete }) {
  const { user, comment, createdAt } = review;

  const handleDelete = () => {
    // Call the onDelete function with the review id
    onDelete(review._id);
  };

  return (
    <>
      <div className="review-box">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img src={user.profileImage} alt="" width={"50px"} />
            <div>
              <h3 className="review-card-title">{capitalizeWords(user?.nickName)}</h3>
            </div>
          </div>
          <button className="delete-btn" onClick={handleDelete}>
            Delete
          </button>
        </div>
        <div className="d-flex align-items-center">
          <Rating  rating={review?.rating} />
          <div>
            <p className="review-card-text m-0">{comment}</p>
          </div>
        </div>
        <p className="review-card-text m-0">Reviewed on {new Date(createdAt).toLocaleDateString("en-IN")}</p>
        <p className="customer-review-text m-0">
          <LongText content={comment} limit={10} />
        </p>
      </div>
    </>
  );
}

export default CustomerReviewCard;
