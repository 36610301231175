import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import adImg from "../assets/ad-img.png";
import TopWriter from "../components/answer/TopWriter";
import RecentWriter from "../components/answer/RecentWriter";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import WritersCard from "../components/question/WritersCard";
import { useNavigate } from "react-router-dom";
import WriterAddQuestion from "../components/AddQuestionModal/WriterAddQuestion";
import { baseUrl } from "../context/baseUrl";
import ReaderCard2 from "../components/reader/ReaderCard2";

function WriterClubPage() {
  const [IsOpen, setIsOpen] = useState(false);
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [writes, setWrites] = useState(null);
  const [qnaData, setQnaData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  async function getQnAData() {
    try {
      const res = await axios.get(`${baseUrl}/rnw?type=writer`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setQnaData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching Q&A data:", error);
    }
  }
  useEffect(() => {
    getQnAData();
  }, []);

  async function getWriters() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/content/getallcontent/likes?content_type=WritersClub`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setWrites(res.data.data);
    } catch (e) {}
  }
  useEffect(() => {
    getWriters();
  }, []);

  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }
  
  const filteredQnAData = qnaData.filter((question) =>
    question?.question?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  var allWrites = null;
  if (writes !== null) {
    allWrites = writes.map((element, index) => {
      // .likes.includes("userId")?liked:notliked
      return (
        <>
          <div key={index}>
            <WritersCard
              title={element.heading}
              author={element.nickName}
              writerId={element._id}
              description={element.description}
              date={element.createdAt}
              likes={element.likes}
              likesCount={element.likesCount}
              comments={element.commentArray}
              like={element}
            />
          </div>
        </>
      );
    });
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div
            className="d-flex justify-content-between path-box path-box2"
            style={{ paddingRight: "0" }}
          >
            <div className="d-flex align-items-center">
              <p
                className="path-name m-0"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home /
              </p>
              <p
                className="path-name-bold m-0"
                onClick={() => {
                  navigate("/writer-club");
                }}
              >
                &nbsp;Writer Club
              </p>
            </div>
            <div className="search-container" style={{ padding: "auto" }}>
              <input
                type="text"
                placeholder="Search questions..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <i class="bi bi-search"></i>
            </div>
            <button className="add-question" onClick={() => setIsOpen(true)}>
              Add Question
            </button>
          </div>
          <div className="answer-box">
            {/* <div className="d-flex">
              <div>
                <img src={Img} alt="" />
              </div>
              <div className="d-flex flex-column ps-3">
                <div className="d-flex align-items-center">
                  <p className="question-title m-0">Chetan Goyal</p>
                  <ul className="m-0">
                    <li>
                      <a href="">Follow</a>
                    </li>
                  </ul>
                </div>
                <div className="question-time">
                  <i className="bi bi-clock"></i> 5 hrs
                </div>
              </div>
            </div> */}
            {/* <h1 className="answer-card-title">
              What do you mean by public facilities?
            </h1> */}
            {/* <div className="d-flex align-items-center pt-3">
              <div className="like d-flex align-items-center">
                <i className="bi bi-heart"></i> Like
              </div>
              <div className="comment d-flex align-items-center">
                <i className="bi bi-chat"></i>2 Comment
              </div>
            </div> */}
            {/* <div className="comment-card">
              <div className="d-flex align-items-center">
                <img src={Img1} className="profile-img" alt="" />
                <div className="comment-content">
                  <i className="bi bi-chevron-left"></i>
                  <h6 className="m-0 comment-title">
                    Chetan Goyal{" "}
                    <span className="question-time">
                      <i className="bi bi-clock"></i> 5 hrs
                    </span>
                  </h6>
                  <p className="m-0 comment-text">
                    Public facilities are services provided by the government to
                    its citizens.{" "}
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="comment-card">
              <div className="d-flex align-items-center">
                <img src={Img2} className="profile-img" alt="" />
                <div className="comment-content">
                  <i className="bi bi-chevron-left"></i>
                  <h6 className="m-0 comment-title">
                    Chetan Goyal{" "}
                    <span className="question-time">
                      <i className="bi bi-clock"></i> 5 hrs
                    </span>
                  </h6>
                  <p className="m-0 comment-text">
                    Public facilities are services provided by the government to
                    its citizens.{" "}
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="comment-answer">
              <div className="d-flex align-items-center">
                <img src={Img3} alt="" className="profile-img" />
                <div className="d-flex w-100">
                  <input
                    type="text"
                    placeholder="Add your Answer"
                    className="comment-content"
                  />
                  <button className="send-btn">Comment</button>
                </div>
              </div>
            </div> */}
            {/* {allWrites} */}
            {filteredQnAData?.length > 0 &&
            filteredQnAData?.map((question) => (
              // <div key={question._id}>
              <ReaderCard2
                data={question}
                getQnAData={getQnAData}
              />
              // </div>
            ))}
          </div>
        </div>
        <div className="right-box">
          {/* <h2 className="right-title text-center">Writer review</h2>
          <div className="suggest-box">
            <TopWriter />
            <TopWriter />
            <TopWriter />
          </div>
          <div className="suggest-box">
            <ul
              className="nav nav-tabs justify-content-around"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Top Writer
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Recent Writer
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <TopWriter />
                <TopWriter />
                <TopWriter />
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
              </div>
            </div>
          </div> */}
          <h2 className="recent-title text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
      {IsOpen && <WriterAddQuestion setIsOpen={setIsOpen}  getQnAData={getQnAData}  />}
    </>
  );
}

export default WriterClubPage;
