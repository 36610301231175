import React, { useContext, useState } from "react";
import logo from "../../assets/logo.png";
import searchIcon from "../../assets/search-Icon.png";
import profileIcon from "../../assets/profile-icon.png";
import likeIcon from "../../assets/like-icon.png";
import cartIcon from "../../assets/cart-icon.png";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarContext from "../../context/SidebarContext";
import { Snackbar } from "@mui/material";
import axios from "axios";
import LoginContext from "../../context/login/LoginContext";
import { baseUrl } from "../../context/baseUrl";

function Header() {
  const side = useContext(SidebarContext);
  const login = useContext(LoginContext);
  const { loggedIn } = login;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { openLeftSidebar } = side;
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValue = {
    content_type: "",
    category: "",
    sub_category: "",
    heading: "",
    description: "",
    author: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const pathName = useLocation();
  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function handleOnSubmit() {
    const {
      content_type,
      category,
      sub_category,
      heading,
      description,
      author,
    } = formData;
    if (content_type === "") return setErrorMessage("Content Type is Required");
    if (category === "") return setErrorMessage("Category is Required");
    if (sub_category === "") return setErrorMessage("Sub Category is Required");
    if (heading === "") return setErrorMessage("heading is Required");
    if (author === "") return setErrorMessage("Author Type is Required");
    if (description === "")
      return setErrorMessage("Description Type is Required");
    try {
      const body = JSON.stringify({
        content_type: formData.content_type,
        category: formData.category,
        sub_category: formData.sub_category,
        heading: formData.heading,
        description: formData.description,
        author: formData.author,
      });
      const res = await axios({
        method: "post",
        url: `${baseUrl}/content/create`,
        data: body,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setFormData(initialValue);
    } catch (e) {}
  }
  return (
    <>
      <header>
        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div className="row align-items-center main-nav">
          <div className="col-4">
            <div className="logo-list">
           
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="" className="img-fluid" />
                </Link>
              </div>
              <div
                className="list"
                style={toggleSideBar ? { right: 0 } : { right: "-200px" }}
              >
                <ul>
                  <li>
                    <Link to="/product">BUY</Link>
                  </li>
                  <li>
                    {/* <Link to="/sell">SELL</Link> */}
                    <div className="dropdown">
                      <Link>SELL</Link>
                      <ul className="dropdown-menu">
                        <li style={{ padding: "10px" }}>
                          <Link to="/sell">Add Product</Link>
                        </li>
                        <li
                          style={{ paddingTop: "0px", paddingBottom: "10px" }}
                        >
                          <Link to="/productlist">Product List</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown">
                      <Link>SHARE</Link>
                      <ul className="dropdown-menu">
                        <li style={{ padding: "10px" }}>
                          <Link to="/members">member</Link>
                        </li>
                        <li
                          style={{ paddingTop: "0px", paddingBottom: "10px" }}
                        >
                          <Link to="/">my page</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <div className="social-media-mobile">
                  {token ? (
                    <a
                      href="/#/profile"
                      className={`${
                        pathName.pathname === "/profile" ? "active-header" : ""
                      }`}
                    >
                      <i className="bi bi-person-fill"></i>
                    </a>
                  ) : (
                    <a
                      href="/#/login"
                      className={`${
                        pathName.pathname === "/login" ? "active-header" : ""
                      }`}
                    >
                      LOGIN
                    </a>
                  )}
                  <a href="">
                    <img src={likeIcon} alt="" />
                  </a>
                  <a href="">
                    <img src={cartIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search..."
                className="search-input"
              />
              <img src={searchIcon} className="search-icon" alt="" />
            </div>
          </div>
          <div className="col-4 navicons">
            <div className="social-media-desktop">
              {token ? (
                <a
                  href="/#/profile"
                  className={`${
                    pathName.pathname === "/profile" ? "active-header" : ""
                  }`}
                >
                  <i className="bi bi-person-fill"></i>
                </a>
              ) : (
                <a
                  href="/#/login"
                  className={`${
                    pathName.pathname === "/login" ? "active-header" : ""
                  }`}
                >
                  LOGIN
                </a>
              )}
              <Link to="/wishlist">
                <i className="bi bi-heart-fill"></i>
              </Link>
              {/* <a href="">
                <i className="bi bi-heart-fill"></i>
              </a> */}
              <Link to="/cart">
                <i className="bi bi-cart-fill"></i>
              </Link>
            </div>
          <div className="d-flex">
          <button className="bras-btn" onClick={openLeftSidebar}>
                {/* <i className="fa fa-regular fa-bars"></i> */}
                <i style={{fontSize:"25px"}} class="bi bi-arrow-right-circle"></i>
              </button>
            
            <button onClick={() => openSidebar()} className="bras-btn">
              <i style={{fontSize:'25px'}} className="fa fa-regular fa-bars"></i>
            </button>
          </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
