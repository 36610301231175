import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import bookCustomer from "../assets/book-customer.png";
import star from "../assets/star.svg";
// import reviewWrite from "../assets/review-write.png";
// import CustomerReviewCard from "../components/book-review/CustomerReviewCard";
import RightCustomerCard from "../components/book-review/RightCustomerCard";
import SidebarContext from "../context/SidebarContext";
// import rightArrow from "../assets/right-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";
import { capitalizeParagraph, capitalizeWords } from "../common/toUpperCase";
import Rating from "../components/Rating";

function CollegeDetail() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  var params = useParams();
  var productId = params.id;
  const { id } = useParams();
  const [content, setContent] = useState(null);
  console.log(content);
  useEffect(() => {
    async function fetchContent() {
      try {
        const response = await axios.get(
          `${baseUrl}/content/getacontent/${id}`
        );
        setContent(response?.data?.data); // Assuming the response.data is the content object
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    }

    fetchContent();
  }, [id]);

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p
              className="path-name m-0"
              onClick={() => navigate("/book-review")}
            >
              &nbsp;Collage /
            </p>
            <p className="path-name-bold m-0">&nbsp; Collage Detail</p>
          </div>
          <div className="d-flex">
            <img
              src={content?.displayImage || bookCustomer}
              alt=""
              width={"160px"}
            />
            <div className="review-customer-box">
              <p className="book-customer-text mb-2" onClick={()=>navigate(`/member/${content?.userId?._id}`)}>
                {capitalizeWords(content?.nickName)},{" "}
                {capitalizeWords(content?.userId?.country)}
              </p>
              <h2 className="book-customer-title">
                {/* World’s Greatest Books For Personal Growth & Wealth (Set of 4
                Books): Perfect Motivational Gift Set Paperback – 1 August 2019 */}
                {capitalizeWords(content?.heading)}
              </h2>
              <p className="book-customer-text mb-2">
                {capitalizeWords(content?.state)},{" "}
                {capitalizeWords(content?.country)}
              </p>
              <div className="d-flex">
                {/* <img src={star} alt="" /> <img src={star} alt="" />
                <img src={star} alt="" /> <img src={star} alt="" />
                <img src={star} alt="" /> */}
                <Rating rating={(content?.rating||0)/(content?.noOfRates)} />
                <p className="rating-total m-0">{content?.noOfRates>0 && content?.noOfRates} rating</p>
              </div>
              <div className="d-flex align-items-center">
                <button className="center-btn">
                  <a href={`/#/college/college-customer/${productId}`}>
                    Review
                  </a>
                </button>
                <button
                  className="center-btn"
                  onClick={() => navigate(`/collage-question/${productId}`)}
                >
                  <a>Q&Ans</a>
                </button>
              </div>
            </div>
          </div>
          <h2 className="review-title">Product details</h2>
          <div className="product-detail-box">
            <p className="m-0 product-box-text">
              <span className="product-box-title">Publisher : </span>{" "}
              {/* Fingerprint! Publishing; Special Edition (1 August 2019); Prakash
              Books India Pvt Ltd, 113A, Ansari Road, Daryaganj, New
              Delhi-110002, +9111-23265358 */}
              <div
                dangerouslySetInnerHTML={{ __html: content?.description }}
              ></div>
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Language : </span>English
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Paperback : </span>900 Pages
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">ISBN-10 : </span> 9389432014
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">ISBN-13 : </span>
              978-9389432015
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Reading age : </span> Customer
              suggested age: 13 years and up
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Item Weight : </span>750 g
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Dimensions : </span>20.3 x
              25.4 x 4.7 cm
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Country of Origin : </span>{" "}
              India
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Net Quantity : </span> 1 count
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Importer : </span> Prakash
              Books India Pvt Ltd
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Packer : </span> Prakash Books
              India Pvt Ltd
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Customer Reviews : </span>
              <img src={star} alt="" /> <img src={star} alt="" />
              <img src={star} alt="" /> <img src={star} alt="" />
              <img src={star} alt="" style={{ marginRight: "10px" }} />
              22,686 ratings
            </p>
          </div>
        </div>
        <div className="right-box">
          <h2 className="right-title">Products related to this item</h2>
          <div className="right-customer-box">
            <RightCustomerCard  contentType={"Colleges"}/>
            {/* <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard /> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default CollegeDetail;
