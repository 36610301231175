import React, { useEffect, useState } from "react";
import logoProfile from "../../assets/question-card-img.png";
import star from "../../assets/star.svg";
import bookReview from "../../assets/book-review.png";
import adImg from "../../assets/ad-img.png";
import flag from "../../assets/flag-in.png";
import axios from "axios";
import { baseUrl } from "../../context/baseUrl";
import { capitalizeFirstLetter } from "../../common/toUpperCase";
import { useNavigate } from "react-router-dom";

const MemberRightSide = () => {
  const navigate = useNavigate();
  const [topuser, setTopUser] = useState(null);

  const getTopUserData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/user/most-followers`);
      if (res?.status == 200) {
        setTopUser(res?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getTopUserData();
  }, []);
  console.log(topuser);
  return (
    <div className="right-box">
    <br/>
      <h2 className="right-title text-center">Member Details</h2>
      <div className="follow-profile">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <div className="d-flex align-items-center">
            <img
              src={topuser?.profileImage || logoProfile}
              alt=""
              style={{ width: "50px", height: "50px", borderRadius: "50px" }}
              onClick={() => {
                navigate(`/member/${topuser?._id}`);
              }}
            />
            <div className="ms-3">
              <h6
                className="all-card-title"
                onClick={() => {
                  navigate(`/member/${topuser?._id}`);
                }}
              >
                {capitalizeFirstLetter(topuser?.nickName)}
              </h6>
              <div className="d-flex justify-content-between align-items-center">
                <p className="d-flex justify-content-between align-items-center m-0">
                  Rating
                  <img src={star} style={{ margin: "0 10px" }} alt="" />
                  <p className="m-0">4.5</p>
                </p>
                <div className="d-flex justify-content-between align-items-center ps-3">
                  <p className="mb-0 me-2">Following</p>
                  <p className="m-0">{topuser?.following?.length || 0}</p>
                </div>
              </div>
              <p className="country-name mb-0 mt-2">
                India <img src={flag} alt="" />
                {/* <button className="follow-btn ms-3" onClick={handleFollow}>
                Follow
              </button> */}
              </p>
            </div>
          </div>
        </div>
        <div className="follow-content d-flex justify-content-between align-items-center">
          <p className="m-0">Blogs</p>
          <p className="m-0">{topuser?.blogs}</p>
        </div>
        <div className="follow-content d-flex justify-content-between align-items-center">
          <p className="m-0">Book Review</p>
          <p className="m-0">{topuser?.books}</p>
        </div>
        <div className="follow-content d-flex justify-content-between align-items-center">
          <p className="m-0">Quotes</p>
          <p className="m-0">{topuser?.quotes}</p>
        </div>
        <div className="follow-content d-flex justify-content-between align-items-center">
          <p className="m-0">Colleges</p>
          <p className="m-0">{topuser?.colleges}</p>
        </div>
      </div>
      {/* <h2 className="right-title mt-3 text-center">Recent book review</h2>
    <div className="top-member-card">
      <div className="d-flex align-items-center review-card div justify-content-between">
        <div className="d-flex align-items-center">
          <img src={bookReview} alt="" />
          <div className="ms-2">
            <p className="m-0 review-card-text">Rich dad and poor dad</p>
          </div>
        </div>
        <p className="m-0 review-rating-text">
          5.0 <img src={star} alt="" />
        </p>
      </div>
      <div className="d-flex align-items-center review-card div justify-content-between">
        <div className="d-flex align-items-center">
          <img src={bookReview} alt="" />
          <div className="ms-2">
            <p className="m-0 review-card-text">Rich dad and poor dad</p>
          </div>
        </div>
        <p className="m-0 review-rating-text">
          5.0 <img src={star} alt="" />
        </p>
      </div>
      <div className="d-flex align-items-center review-card div justify-content-between">
        <div className="d-flex align-items-center">
          <img src={bookReview} alt="" />
          <div className="ms-2">
            <p className="m-0 review-card-text">Rich dad and poor dad</p>
          </div>
        </div>
        <p className="m-0 review-rating-text">
          5.0 <img src={star} alt="" />
        </p>
      </div>
    </div> */}
      <h2 className="right-title mt-3 text-center">Place for ad</h2>
      <img src={adImg} alt="" />
    </div>
  );
};

export default MemberRightSide;
