import React, { useContext, useState } from "react";
import Img from "../../assets/question-card-img.png";
import axios from "axios";
import LoginContext from "../../context/login/LoginContext";
import { Link, useNavigate } from "react-router-dom";
import ReaderContext from "../../context/reader/ReaderContext";
import { baseUrl } from "../../context/baseUrl";
import { Modal } from "react-bootstrap";
import { capitalizeParagraph } from "../../common/toUpperCase";

function ReaderCard2({
  author,
  description,
  date,
  title,
  likes,
  comments,
  readerId,
  likesCount,
  data,
  contentId,
  getQnAData,
}) {
  console.log(data);
  const login = useContext(LoginContext);
  const reader = useContext(ReaderContext);
  const { loggedIn } = login;
  const navigate = useNavigate();
  const { readerComment, setReaderComment, readerLike, setReaderLike } = reader;
  const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [commentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [answer, setAnswer] = useState("");
  const [clickToggle, setClickToggle] = useState(false);
  const [newAnswer, setNewAnswer] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("userId") || "");

  const handleLoginCheck = () => {
    if (!userId) {
      navigate("/login");
      return false;
    }
    return true;
  };

  async function handlePostAnswer() {
    // if (loggedIn === true) {
    try {
      const { _id } = data;
      const body = JSON.stringify({
        answer: answer,
        contentId: contentId,
        questionId: _id,
      });
      const res = await axios({
        method: "post",
        url: `${baseUrl}/qna/answer`,
        data: body,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res?.status == 200) {
        alert("Answer Post is Done.");
        setAnswer("");
        getQnAData(contentId);
      }
    } catch (error) {
      // Handle error
    }
    // } else {
    //   navigate("/login");
    // }
  }

  // Function to handle closing the modal
  const handleCloseModal = () => setShowModal(false);

  const handleUpdateAnswer = async (id) => {
    if (newAnswer == "") {
      return alert("Please Enter Answer.");
    }
    try {
      const res = await axios.put(
        `${baseUrl}/qna/answer`,
        { answerId: id, newAnswer, contentId, questionId: data?._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status == 200) {
        setNewAnswer("");
        alert("Answer Updated is Done.");
        getQnAData(contentId);
      }
    } catch (error) {}
    setClickToggle(false);
  };

  const handleDeleteAnswer = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/qna/answer`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { answerId: id, contentId, questionId: data?._id },
      });
      if (res?.status == 200) {
        alert("Answer Deleted is Done.");
        getQnAData(contentId);
      }
    } catch (error) {}
    setClickToggle(false);
  };

  function toggleCommentBox() {
    setCommentBox(!commentBox);
  }
  async function handleOnComment(id) {
    // e.preventDefault();
    if (handleLoginCheck) {
      // const id = e.target.id;
      try {
        const body = JSON.stringify({
          comment: comment,
          rnwId: id,
          userId,
        });
        const res = await axios({
          method: "post",
          url: `${baseUrl}/rnw/comment`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setComment("");
        setReaderComment(res.data.data.commentArray);
        setNewComment(true);
        getQnAData();
        toggleCommentBox()
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  async function handleOnLike(id, action) {
    if (handleLoginCheck) {
      try {
        const body = JSON.stringify({
          rnwId: id,
          userId,
          action,
        });
        const res = await axios({
          method: "post",
          url: `${baseUrl}/rnw/like`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setReaderLike(res.data.data.likes);
        setNewLike(true);
        getQnAData();
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }

  // let allComment;
  // if (newComment === false) {
  //   allComment = comments.map((element, index) => {
  //     return (
  //       <>
  //         <div className="d-flex">
  //           <img src={Img} alt="" className="comment-profile" />
  //           <div className="ms-3">
  //             <p className="comment-text">{element.comment}</p>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   });
  // } else {
  //   allComment = readerComment.map((element, index) => {
  //     return (
  //       <>
  //         <div className="d-flex">
  //           <img src={Img} alt="" className="comment-profile" />
  //           <div className="ms-3">
  //             <p className="comment-text">{element.comment}</p>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   });
  // }
  return (
    <>
      <div
        className="question-card"
        style={
          commentBox === true
            ? { marginBottom: "0px" }
            : { marginBottom: "18px" }
        }
      >
        <Link
          // to={`/reader-club/reader-club-detail/${readerId}`}
          style={{ textDecoration: "none" }}
        >
          <h1
            className="question-card-title"
            onClick={() => navigate(`/rnw-ans/${data?._id}`)}
          >
            {capitalizeParagraph(data?.question)}
          </h1>
        </Link>
        <div className="d-flex align-items-center pt-3">
          <div
            className="like d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => handleOnLike(data?._id, "like")}
          >
            {data?.likes?.some(
              (like) => like.userId === localStorage.getItem("userId")
            ) ? (
              <i className="fa fa-solid fa-heart"></i>
            ) : (
              <i className="bi bi-heart"></i>
            )}{" "}
            {data?.likes?.length > 0
              ? data?.likes?.length + " " + "Like"
              : 0 + " " + "Like"}
          </div>
          <div
            className="comment d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={toggleCommentBox}
          >
            <i className="bi bi-chat"></i>
            {/* {newComment === false
              ? comments?.length + " "
              : readerComment?.length + " "} */}
            {data?.comments?.length > 0
              ? data?.comments?.length + " "
              : 0 + " "}
            Comment
          </div>
        </div>
      </div>
      {commentBox && (
        <div className="comment-boxes">
          {data && data?.comments?.length > 0 && (
            <div
              className="comments-container"
              style={{
                maxHeight: "250px",
                overflowY: "scroll",
                marginTop: "10px",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              {data?.comments?.map((element, index) => (
                <div className="d-flex mb-2" key={index}>
                  <img src={Img} alt="" className="comment-profile" />
                  <div className="ms-3">
                    <p className="comment-text">{element.comment}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="d-flex align-items-center">
            <img src={Img} alt="" className="comment-profile" />
            <input
              className="w-100 mx-3"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add your comment....."
            />
            <button
              className="blog-comment-btn"
              id={readerId}
              onClick={() => handleOnComment(data?._id)}
            >
              Comment
            </button>
          </div>
        </div>
      )}
      {/* {commentBox && (
        <div
          className="comments-container"
          style={
            commentBox === true
              ? { marginBottom: "18px" }
              : { marginBottom: "0px" }
          }
        >
          {allComment}
        </div>
      )} */}
      <Modal show={showModal} onHide={handleCloseModal} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Answers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Render all answers */}
          {data?.answers.map((answerData) => (
            <div key={answerData._id}>
              <p
                onClick={() =>
                  setClickToggle((prevState) =>
                    prevState === answerData._id ? false : answerData._id
                  )
                }
              >
                {answerData.answer}
              </p>
              {/* Check if the logged-in user is the owner of the answer */}
              {clickToggle === answerData._id &&
                answerData.userId._id === localStorage.getItem("userId") && (
                  <div>
                    {/* Option to update answer */}
                    <input
                      type="text"
                      placeholder="Update Answer Text"
                      value={newAnswer}
                      onChange={(e) => setNewAnswer(e.target.value)}
                    />
                    <button onClick={() => handleUpdateAnswer(answerData._id)}>
                      Update
                    </button>{" "}
                    &nbsp;&nbsp;
                    {/* Option to delete answer */}
                    <button onClick={() => handleDeleteAnswer(answerData._id)}>
                      Delete
                    </button>
                  </div>
                )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          {/* Textarea for posting new answer */}
          <textarea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Your answer..."
          ></textarea>
          {/* Button to post the answer */}
          <button onClick={handlePostAnswer}>Post Answer</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReaderCard2;
