import React, { useContext, useEffect, useState } from "react";
import leftBook from "../../assets/left-book.png";
import collage from "../../assets/collage-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarContext from "../../context/SidebarContext";
import axios from "axios";
import { baseUrl } from "../../context/baseUrl";

function LeftSidebar() {
  const pathName = useLocation();
  const side = useContext(SidebarContext);
  const { setLeftSidebar } = side;
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([]);
  const [contentId, setContentId] = useState("");

  function AddContent() {
    navigate("/add-content");
  }
  async function getCategory(Id) {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/types/getatype/${Id}`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setCategoryData([res.data.data]);
    } catch (e) {}
  }

  useEffect(() => {
    if (pathName.pathname === "/colleges") {
      setContentId("64a7aabb165fde14c3536c28");
    }
    if (pathName.pathname === "/book-review") {
      setContentId("64a7ab9e165fde14c3536c38");
    }
    if (pathName.pathname === "/blog") {
      setContentId("64a7aaee165fde14c3536c2c");
    }
    if (pathName.pathname === "/reader-club") {
      setContentId("64a7abb8165fde14c3536c3b");
    }
    if (pathName.pathname === "/writer-club") {
      setContentId("64a7abd4165fde14c3536c3e");
    }
    if (pathName.pathname === "/quotes") {
      setContentId("651d37e81adce5a850d049ce");
    }
    // getCategory(contentId);
  }, [contentId]);
  console.log();
  return (
    <>
      <div className="d-flex flex-column add-content-title align-item-center mt-3">
        <div
          className="text-center mb-3 left-side-add-icon"
          onClick={AddContent}
        >
          {/* <img src={addIcon} alt="" className="left-side-add-icon" /> */}
          <i className="fa fa-solid fa-plus"></i>
        </div>
        <div className="text-center">
          <a href="/add-content">ADD CONTENT</a>
        </div>
      </div>
      <div
        className={` d-flex add-content-list align-item-center ${
          pathName.pathname === "/members" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/members"
          onClick={() => false}
          className={pathName === "/members" ? "active" : ""}
        >
          <i className="bi bi-file-bar-graph-fill"></i>
          Members
        </a>
      </div>
      <div
        className={` d-flex add-content-list align-item-center ${
          pathName.pathname === "/book-review" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/book-review"
          onClick={() => setLeftSidebar(false)}
          className={pathName === "/#/book-review" ? "active" : ""}
        >
          <i className="bi bi-star"></i>Books
        </a>
      </div>
      <div
        className={`d-flex add-content-list align-item-center ${
          pathName.pathname === "/blog" ? "active-header" : ""
        } ${pathName.pathname === "/blog/blog-detail" ? "active" : ""}`}
      >
        <a
          href="/#/blog"
          onClick={() => setLeftSidebar(false)}
          className={pathName === "/blog" ? "active" : ""}
        >
          <i className="bi bi-film"></i>Blogs
        </a>
      </div>
      <div
        className={` d-flex add-content-list align-item-center ${
          pathName.pathname === "/quotes" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/quotes"
          onClick={() => setLeftSidebar(false)}
          className={pathName === "/#/book-review" ? "active" : ""}
        >
          <i className="bi bi-chat-dots-fill"></i>Quotes
        </a>
      </div>
      <div
        className={` d-flex add-content-list align-item-center ${
          pathName.pathname === "/colleges" ? "active-header" : ""
        }`}
      >
        <a href="/#/colleges" onClick={() => setLeftSidebar(false)}>
          <img
            src={collage}
            style={{ height: "20px" }}
            alt=""
            className="me-2"
          />{" "}
          Colleges
        </a>
      </div>
      <div
        className={` d-flex add-content-list align-item-center ${
          pathName.pathname === "/reader-club" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/reader-club"
          onClick={() => setLeftSidebar(false)}
          className={pathName === "/#/reader-club" ? "active" : ""}
        >
          <i className="fa fa-regular fa-book"></i>Readers Club
        </a>
      </div>
      <div
        className={` d-flex add-content-list align-item-center ${
          pathName.pathname === "/writer-club" ? "active-header" : ""
        }`}
      >
        <a
          href="/#/writer-club"
          onClick={() => setLeftSidebar(false)}
          className={pathName === "/#/writer-club" ? "active" : ""}
        >
          <i className="bi bi-pencil-square"></i> Writers Club
        </a>
      </div>
      {/* <h6 className="m-0 pt-3 category-sidebar-title">Category</h6>
      <div className="d-flex category-content-title align-item-center">
        <select>
          <option value="">--Select--</option>
          {categoryData.map((element) => {
            return element.category.map((category, id) => {
              return (
                <>
                  <option
                    value={category}
                    style={{ textTransform: "uppercase" }}
                    key={id}
                  >
                    {category}
                  </option>
                </>
              );
            });
          })}
        
        </select>
      </div> */}
      {/* <h6 className="m-0 pt-3 category-sidebar-title">Sub Category</h6> */}
      {/* <div className="d-flex category-content-title align-item-center">
        <select>
          <option value="">--Select--</option>
          {categoryData.map((element) => {
            return element.subCategory.map((subCategory, id) => {
              return (
                <>
                  <option
                    style={{ textTransform: "uppercase" }}
                    value={subCategory}
                    key={id}
                  >
                    {subCategory}
                  </option>
                </>
              );
            });
          })}
        </select>
      </div> */}
      {/*  <div className="d-flex add-content-list align-item-center">
        <a href="">
          <img src={nonIcon} alt="" className="left-side-icon" />
          Non Fiction
        </a>
      </div> */}
      {/* <div className="d-flex add-content-list align-item-center">
        <a href="">
          {" "}
          <i className="bi bi-people-fill"></i>Academic Books
        </a>
      </div> */}
      {/*  <div className="d-flex add-content-list align-item-center">
        <a href="">
          {" "}
          <i className="bi bi-heart"></i>Magazines
        </a>
      </div> */}
      {/*  <div className="d-flex add-content-list align-item-center">
        <a href="">
          <i className="bi bi-controller"></i>Newspaper
        </a>
      </div> */}
      {/*  <div className="d-flex add-content-list align-item-center">
        <a href="">
          <img src={FictionIcon} alt="" className="left-side-icon" /> Fiction
        </a>
      </div> */}
      {/* <div className="d-flex add-content-list align-item-center">
        <a href="">
          <img src={comicIcon} alt="" className="left-side-icon" /> Comic
        </a>
      </div> */}
      {/* <div className="d-flex add-content-list align-item-center">
        <a href="">
          <i className="bi bi-info-circle-fill"></i>Other
        </a>
      </div> */}
      <div className="text-center">
        <img src={leftBook} style={{ marginTop: "70px" }} alt="" />
      </div>
    </>
  );
}

export default LeftSidebar;
