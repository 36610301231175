import React, { useContext, useEffect, useState } from "react";
import blogDetail from "../../assets/Rectangle 44.png";
import flag from "../../assets/flag-in.png";
import blogProfile from "../../assets/question-card-img.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import LoginContext from "../../context/login/LoginContext";
import QuotesContext from "../../context/quetes/QuotesContext";
import { baseUrl } from "../../context/baseUrl";
// import BlogModal from "./BlogModal";

function ReaderDetailCard() {
  const login = useContext(LoginContext);
  const quotes = useContext(QuotesContext);

  const { loggedIn } = login;
  const { quotesComment, setQuotesComment, quotesLike, setQuotesLike } = quotes;

  const params = useParams();
  const navigate = useNavigate();

  const [commentBox, setCommentBox] = useState(false);
  const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [quote, setQuotes] = useState(null);
  const [comment, setComment] = useState("");

  function toggleCommentBox() {
    setCommentBox(!commentBox);
  }

  async function getBlogs() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/content/getacontent/${params.readerId}`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setQuotes([res.data.data]);
    } catch (e) {}
  }
  useEffect(() => {
    getBlogs();
  }, []);

  async function handleOnComment(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          comment: comment,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/postcomment/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setComment("");
        setQuotesComment(res.data.data.commentArray);
        setNewComment(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  async function handleOnLike(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          contentId: id,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/updatelikes/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setQuotesLike(res.data.data.likes);
        setNewLike(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  if (quote === null) {
    return <></>;
  }

  return (
    <>
      {quote.map((element, index) => {
        let allComment;
        if (newComment === false) {
          allComment = element.commentArray.map((element, index) => {
            return (
              <>
                <div className="d-flex">
                  {/* <img src={Img} alt="" className="comment-profile" /> */}
                  <div className="ms-3">
                    <p className="comment-text">{element.comment}</p>
                  </div>
                </div>
              </>
            );
          });
        } else {
          allComment = quotesComment.map((element, index) => {
            return (
              <>
                <div className="d-flex">
                  {/* <img src={Img} alt="" className="comment-profile" /> */}
                  <div className="ms-3">
                    <p className="comment-text">{element.comment}</p>
                  </div>
                </div>
              </>
            );
          });
        }
        /* console.log(element); */
        return (
          <>
            <div className="blog-detail-card">
              <h1 className="blog-detail-title">{element.heading}</h1>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <div className="d-flex align-items-center">
                  <div>
                    <img src={blogProfile} className="pe-2" alt="" />
                  </div>
                  <div>
                    <h6 className="m-0">{element.author}</h6>
                    <div className="d-flex align-items-center">
                      <p className="m-0 ">India</p>{" "}
                      <img className="ps-2" src={flag} alt="" />
                    </div>
                  </div>
                </div>
                <div className="blog-date">
                  &nbsp; {moment(element.createdAt).format("DD MMM YYYY")}
                </div>
              </div>
              <div className="blog-detail-img">
                <img src={blogDetail} alt="" />
              </div>

              <p className="blog-detail-description pt-3">
                {element.description}
              </p>
              <div className="d-flex align-items-center">
                <div
                  className="like d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnLike(element._id)}
                >
                  {element.likes.includes(localStorage.getItem("userId")) ? (
                    <i className="fa fa-solid fa-heart"></i>
                  ) : (
                    <i className="bi bi-heart"></i>
                  )}
                  {newLike === false
                    ? element.likes.length + " " + "Like"
                    : quotesLike.length + " " + "Like"}
                </div>
                <div className="comment d-flex align-items-center">
                  <button
                    type="button"
                    className="comment-btn"
                    onClick={toggleCommentBox}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                  >
                    <i className="bi bi-chat"></i>
                    {element.commentArray.length} Comment
                  </button>
                </div>
              </div>
            </div>
            <div
              className="blog-detail-comment"
              style={{ display: commentBox ? "block" : "none" }}
            >
              {allComment}
              <div className="comment-boxes blog-comment-box">
                <div className="d-flex align-items-center">
                  <input
                    className="w-100"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Add your comment....."
                  />
                  <button
                    className="blog-comment-btn"
                    onClick={() => handleOnComment(element._id)}
                  >
                    Comment
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default ReaderDetailCard;
