import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import flag from "../assets/flag-in.png";
import img from "../assets/all-card.png";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import ScreenLoader from "../loader/ScreenLoader";
import MemberRightSide from "./Member/MemberRightSide";
import MemberUserRightSide from "./Member/MemberUserRightSide";
import "./memberpage.css"

function MemberPage() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const someQueryParam = queryParams.get("content");
  console.log(someQueryParam);
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [userData, setUserData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [contentType, setContentType] = useState("Blogs");
  const [loading, setLoading] = useState(false);
  const [topuser, setTopUser] = useState(null);
  const navigate = useNavigate();

  const getTopUserData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/user/getauser/${id}`);
      if (res?.status == 200) {
        setTopUser(res?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${baseUrl}/content/getbyuser/${id}`);
        if (res?.status === 200) {
          setUserData(res?.data?.data);
          if (someQueryParam) {
            setContentType(someQueryParam)
            filterContent(res?.data?.data?.content, someQueryParam);
          } else {
            filterContent(res?.data?.data?.content, "Blogs");
          }
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    getData();
    getTopUserData();
  }, [id]);

  

  const filterContent = (data, type) => {
    let filtered = data.filter(
      (item) => item.contentType.toLowerCase() === type.toLowerCase()
    );
    if(filtered && filtered?.length<=0){
      return
    }
    if (type.toLowerCase() === "books" || type.toLowerCase() === "colleges") {
      filtered = filtered?.sort((a, b) => b.rating - a.rating);
    } else if (
      type.toLowerCase() === "blogs" ||
      type.toLowerCase() === "quotes"
    ) {
      filtered = filtered?.sort((a, b) => b.likes.length - a.likes.length);
    }

    setFilteredData(filtered);
  };

  const handleContentChange = (type) => {
    setContentType(type);
    if (userData) {
      filterContent(userData?.content, type);
    }
  };

  const handlefilterupdate = (val) => {
    let filtered = userData?.content?.filter(
      (item) => item.contentType.toLowerCase() === contentType.toLowerCase()
    );
    if(filtered && filtered?.length<=0){
      return
    }
    if (
      contentType.toLowerCase() === "books" ||
      contentType.toLowerCase() === "colleges"
    ) {
      if (val == "top") {
        filtered = filtered?.sort((a, b) => b.rating - a.rating);
      } else if (val == "new") {
        filtered = filtered?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } else if (val == "old") {
        filtered = filtered?.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      }
    } else if (
      contentType.toLowerCase() === "blogs" ||
      contentType.toLowerCase() === "quotes"
    ) {
      if (val == "top") {
        filtered = filtered?.sort((a, b) => b.likes.length - a.likes.length);
      } else if (val == "new") {
        filtered = filtered?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } else if (val == "old") {
        filtered = filtered?.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      }
    }
    setFilteredData(filtered);
  };

  const handleNavigate = (data) => {
    console.log(data);
    if (data.contentType == "Blogs") {
      navigate(`/blog/blog-detail/${data?._id}`);
    } else if (data.contentType == "Quotes") {
      navigate(`/quotes/quotes-detail/${data?._id}`);
    } else if (data.contentType == "Books") {
      navigate(`/book/book-detail/${data?._id}`);
    } else if (data.contentType == "Colleges") {
      navigate(`/collage-detail/${data?._id}`);
    }
  };
  const isActive = (type) => (contentType == type ? "active-button-content" : "");
  return (
    <>
      <Header />
      <section className="hero-section">
        {loading && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
        <div
          className="left-overlay"
          onClick={openLeftSidebar}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
      <br/>

          <div className="all-card">
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <img
                  src={topuser?.profileImage}
                  style={{
                    height: "139px",
                    width: "139px",
                    borderRadius: "6px",
                  }}
                  alt=""
                />
              </div>
              <div className="col-md-9 col-sm-12">
                <h6 className="all-card-title">{topuser?.nickName}</h6>
                <div className="d-flex align-items-center">
                  <p className="country-name">
                    {topuser?.country || "India"} <img src={flag} alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-buttons">
            <button
              className={isActive("Blogs")}
              onClick={() => handleContentChange("Blogs")}
            >
              Blogs
            </button>
            <button
              className={isActive("Quotes")}
              onClick={() => handleContentChange("Quotes")}
            >
              Quotes
            </button>
            <button
              className={isActive("Books")}
              onClick={() => handleContentChange("Books")}
            >
              Books
            </button>
            <button
              className={isActive("Colleges")}
              onClick={() => handleContentChange("Colleges")}
            >
              Colleges
            </button>
            <button onClick={() => handlefilterupdate("top")}>Top Rated</button>
            <button onClick={() => handlefilterupdate("new")}>Recent</button>
            <button onClick={() => handlefilterupdate("old")}>Oldest</button>
          </div>
          <div className="filter-buttons">
          
          </div>
          <br/>
          <div className="user-content">
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <div key={index} className="content-card">
                  <h4 onClick={() => handleNavigate(item)}>{item.heading}</h4>
                  <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
              ))
            ) : (
              <p>No {contentType} available</p>
            )}
          </div>
        </div>
        <MemberUserRightSide topuser={topuser} />
      </section>
    </>
  );
}

export default MemberPage;
