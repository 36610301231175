import React, { useContext } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import bookCustomer from "../assets/book-customer.png";
import star from "../assets/star.svg";
import reviewWrite from "../assets/review-write.png";
import CustomerReviewCard from "../components/book-review/CustomerReviewCard";
import RightCustomerCard from "../components/book-review/RightCustomerCard";
import SidebarContext from "../context/SidebarContext";
// import rightArrow from "../assets/right-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";

function ReviewCustomer() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  let params = useParams();
  const bookId = params.id 

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p className="path-name m-0" onClick={() => navigate("/collage")}>
              &nbsp;Collage Review /
            </p>
            <p className="path-name-bold m-0">&nbsp; Review Customer</p>
          </div>
          <div className="d-flex">
            <img src={bookCustomer} alt="" />
            <div className="review-customer-box">
              <h2 className="book-customer-title">
                World’s Greatest Books For Personal Growth & Wealth (Set of 4
                Books): Perfect Motivational Gift Set Paperback – 1 August 2019
              </h2>
              <p className="book-customer-text mb-2">
                by (Author), Napoleon Hill (Author), Dr. Joseph Murphy (Author)
              </p>
              <div className="d-flex">
                <img src={star} alt="" /> <img src={star} alt="" />
                <img src={star} alt="" /> <img src={star} alt="" />
                <img src={star} alt="" />
                <p className="rating-total m-0">106 rating</p>
              </div>
              <div className="d-flex align-items-center">
                <button className="center-btn">
                  <a href={`/#/product/review-customer/${bookId}`}>Review</a>
                </button>
                <button className="center-btn">
                  <a href="/#/collage-question">Q&Ans</a>
                </button>
              </div>
            </div>
          </div>
          <h2 className="review-title">Review</h2>
          <CustomerReviewCard />
          <CustomerReviewCard />
          <CustomerReviewCard />
          <div className="review-bottom-box">
            <div className="review-bottom">
              <h5 className="review-bottom-title">Review this Book</h5>
              <p className="review-bottom-text">
                Share your thoughts with other customers
              </p>
              <div className="review-bottom-write-box">
                <input
                  type="text"
                  className="review-write-input"
                  placeholder="Write a product review"
                />
                <img src={reviewWrite} className="review-input-img" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="right-box">
          <h2 className="right-title">Products related to this item</h2>
          <div className="right-customer-box">
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewCustomer;
