import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
// import TopWriter from "../components/answer/TopWriter";
// import RecentWriter from "../components/answer/RecentWriter";
import adImg from "../assets/ad-img.png";
import SidebarContext from "../context/SidebarContext";
import book from "../assets/book-review-img.png";
import Rating from "../components/Rating";
import Img from "../assets/question-card-img.png";
import inFlag from "../assets/flag-in.png";
import RecImg from "../assets/recent-writer.png";
// import rightArrow from "../assets/right-arrow.svg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import { capitalizeFirstLetter, capitalizeWords } from "../common/toUpperCase";

function RecentWriter({ college }) {
  const navigate = useNavigate()
  return (
    <>
      <div className="d-flex align-items-center recent-writer-box" onClick={()=>navigate(`/collage-detail/${college?._id}`)}>
        <div>
          <img src={college?.displayImage || RecImg} alt="" width={50} />
        </div>
        <div>
          <h2 className="recent-writer-title">{college?.heading}</h2>
        </div>
      </div>
    </>
  );
}

function TopWriter({ college }) {
  const navigate = useNavigate()
  return (
    <>
      <div className="d-flex align-items-center justify-content-between writer-box" onClick={()=>navigate(`/collage-detail/${college?._id}`)}>
        <div className="d-flex">
          <div className="d-flex align-self-end">
            <img src={college?.displayImage || Img} alt="" width={50} />
          </div>
          <div className="writer-detail">
            <h2 className="m-0">{college?.heading}</h2>
            <p className="writer-flag m-0">
              India <img src={inFlag} alt="" />
            </p>
          </div>
        </div>
        <div>
          <button className="view-btn">View</button>
        </div>
      </div>
    </>
  );
}

function BookCard({
  heading,
  description,
  quotesId,
  likes,
  likesCount,
  comments,
  like,
  index,
}) {
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
        <div className="book-review-box text-center">
          <Link
            to={`/collage-detail/${quotesId}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="text-center">
              <img
                src={like?.displayImage || book}
                style={{ margin: "auto", width: "200px", height: "200px" }}
                alt=""
              />
            </div>
            <h6 className="book-review-title">{capitalizeWords(heading)}</h6>
            {/* <p className="book-review-text m-0">{description}</p> */}
            {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
            <div
              style={{ border: "none" }}
              className="review-total d-flex justify-content-center"
            >
              <Rating rating={(like?.rating||0)/(like?.noOfRates)} /> {like?.noOfRates>0 && like?.noOfRates}
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

function AcadamyPage() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [quotes, setQuotes] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();

  const handleCategory = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);

    const selectedCategoryData = categoryData.find(
      (cat) => cat._id === selectedCategory
    );
    setSubCategories(
      selectedCategoryData ? selectedCategoryData.subcategories : []
    );
    setSubCategory("");
  };

  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };

  useEffect(() => {
    async function getQuotes() {
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/content/getallcontent/type/Colleges`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setQuotes(res.data.data);
      } catch (e) {}
    }
    getQuotes();
  }, []);

  useEffect(() => {
    async function getCategory(type = "Colleges") {
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/category/all?contentType=colleges`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (res.status === 200) {
          setCategoryData(res.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    }
    getCategory();
  }, []);

  let filteredQuotes = quotes;

  if (category !== "") {
    filteredQuotes = filteredQuotes.filter(
      (blog) => blog.category === category
    );
    if (subCategory !== "") {
      filteredQuotes = filteredQuotes.filter(
        (blog) => blog.sub_category === subCategory
      );
    }
  }

  var allBookCard = null;
  if (filteredQuotes !== null) {
    allBookCard = filteredQuotes.map((element, index) => {
      // element.likes.includes("userId")?liked:notliked
      return (
        <>
          <BookCard
            index={index}
            heading={element.heading}
            quotesId={element._id}
            description={element.description}
            likes={element.likes}
            likesCount={element.likesCount}
            comments={element.commentArray}
            like={element}
          />
        </>
      );
    });
  }

  let topCollages = null;
  let recentCollages = null;

  if (quotes !== null) {
    const shuffledCollages = quotes.sort((a, b) => b.rating - a.rating);
    const randomTopCollages = shuffledCollages.slice(0, 3);
    topCollages = randomTopCollages.map((college, index) => (
      <TopWriter key={index} college={college} />
    ));

    const sortedRecentCollages = quotes.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    recentCollages = sortedRecentCollages
      .slice(0, 3)
      .map((college, index) => <RecentWriter key={index} college={college} />);
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="n-e-w path-box">
         <div className="d-flex">
             <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p
              className="path-name-bold m-0"
              onClick={() => {
                navigate("/colleges");
              }}
            >
              &nbsp;Colleges
            </p>
         </div>
         <div className="d-flex gap-3">
         <select onChange={handleCategory} className="form-select">
            <option value="">All Category</option>
            {categoryData &&
              categoryData.length > 0 &&
              categoryData.map((category, index) => (
                <option
                  value={category._id}
                  style={{ textTransform: "uppercase" }}
                  key={index}
                >
                  {category.categoryName}
                </option>
              ))}
          </select>
          {subCategories.length > 0 && (
            <select
              onChange={handleSubCategory}
              className="form-select "
            >
              <option value="">All Subcategory</option>
              {subCategories.map((subCat, index) => (
                <option value={subCat} key={index}>
                  {subCat}
                </option>
              ))}
            </select>
          )}
         </div>
          </div>
         

          <div className="row">
            {allBookCard}
            {/* <BookCard />
            <BookCard />
            <BookCard />
            <BookCard />
            <BookCard />
            <BookCard />
            <BookCard /> */}
          </div>
        </div>
        <div className="right-box">
          {/* <h2 className="right-title text-center">Collage review</h2>
          <div className="suggest-box">
            <TopWriter />
            <TopWriter />
            <TopWriter />
          </div> */}
          <div className="suggest-box">
          <ul
  className="nav nav-tabs justify-content-around"
  id="myTab"
  role="tablist"
>
  <li className="nav-item" role="presentation">
    <button
      className="nav-link"
      id="home-tab"
      data-bs-toggle="tab"
      data-bs-target="#home"
      type="button"
      role="tab"
      aria-controls="home"
      aria-selected="false"
    >
      Top Collage
    </button>
  </li>
  <li className="nav-item" role="presentation">
    <button
      className="nav-link active"
      id="profile-tab"
      data-bs-toggle="tab"
      data-bs-target="#profile"
      type="button"
      role="tab"
      aria-controls="profile"
      aria-selected="true"
    >
      Recent Collage
    </button>
  </li>
</ul>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {topCollages}
                {/* <TopWriter />
                <TopWriter />
                <TopWriter /> */}
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                {recentCollages}
                {/* <RecentWriter />
                <RecentWriter />
                <RecentWriter />
                <RecentWriter /> */}
              </div>
            </div>
          </div>
          <h2 className="recent-title text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
    </>
  );
}

export default AcadamyPage;
